.content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 6rem;
    max-width: 62.5rem;
    margin: 0px auto;
    padding: 0px 2.5rem;
}

li {
    line-height: 32px;
    margin-bottom: 8px;
    font-family: 'Roboto';
}

h3 {
    font-size: 53px;
    font-weight: 600;
}

h4 {
    font-size: 28px;
    font-weight: 600;
}

p {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    letter-spacing: .02em;
    text-transform: none;
    line-height: 1.8em;
    font-size: 1rem;
    line-height: 1.8;
}

.user-research-section {
    margin-bottom: 80px;
}