.App-header {
    width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.home {
    position: relative;
    z-index: 13;
    font-size: 2rem;
    font-weight: 900;
    color: rgb(33, 33, 33);
    filter: none !important;
    pointer-events: auto !important;
    user-select: auto !important;
  }

  .nav-bar {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    width: 31.25rem;
    background: rgb(255, 255, 255);
  }

  .nav-link {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    margin: 0px 1.25rem;
    padding: 0px;
  }

  .cta-button {
    width: auto;
    height: auto;
    font-weight: 700;
    border-radius: 1rem;
    border: 0.125rem solid rgb(33, 33, 33);
    background: rgb(255, 255, 255);
    transition: all 20ms ease-out 0s;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    margin: 0px;
  }
  
  .cta-button:hover {
    background: 	#FF1493;
    color: rgb(255, 255, 255);
    border: 2px solid 	#FF1493;
  }