.App {
  text-align: center;
}

::before {
  box-sizing: inherit;
}

a {
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: black;
  cursor: pointer;
  font-family: Roboto;
}

a.nav-link:hover {
  color: 	#FF1493
}

.title {
  margin-right: 16px;
  margin-bottom: 0;
  margin-top: 0;
}

.greetings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
}

@media (min-width: 1200px) {
  a {
      color: rgb(33, 33, 33);
  }
}


#hero {
  width: 100%;
  height: auto;
  background: rgb(255, 255, 255);
}

.content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6rem;
  max-width: 62.5rem;
  margin: 0px auto;
  padding: 0px 2.5rem;
}

.description {
  font-size: 1.125rem;
  margin-bottom: 2rem;
}

.tag {
  font-size: 0.975rem;
  line-height: 1rem;
  font-weight: 500;
  color: white;
  letter-spacing: 0.2px;
  background-color: 	#FF1493;
  opacity: 0.8;
  padding: 2px 6px;
  border-radius: 3px;
  max-width: fit-content;
}

.item-link:hover {
  color: #FF1493;
}

.card {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 32px;
}

.card p {
  visibility: hidden;
}

.card:hover {
  background-color: #222222;
  opacity: 0.85;
}

.card:hover p {
  visibility: visible;
}

.card .text {
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  font-family: Roboto;
}